import React, {useContext} from 'react'
import * as styles from './Navbar.module.css';
import { Link } from 'gatsby';
import DarkmodeContext from '../../../state/darkmodel-context';

export default function Navbar() {
    const {darkMode: isDark} = useContext(DarkmodeContext)

    return (
        <div className={`${styles.navbar} ${isDark?styles.navbar_dark:''}`}>
            <div className={styles.logo}>
                <img 
                    src={`/images/logo.svg`}
                    height={20}
                    width={36}
                    alt="logo of raysummee"
                />
            </div>
            <nav>
                <ul>
                    <li>
                        <Link activeClassName={styles.active} to="/">Home</Link>
                    </li>
                    {/* <li>
                        <Link activeClassName={styles.active} to={`/about/`}>About</Link>
                    </li> */}
                    {/* <li>
                        <Link activeClassName={styles.active} to={`/portfolio/`}>Portfolio</Link>
                    </li> */}
                    <li>
                        <Link activeClassName={styles.active} to={`/contact/`}>Contact</Link>
                    </li>
                    <li>
                        <Link activeClassName={styles.active} to={`/blogs/`}>Blogs</Link>
                    </li>
                </ul>
            </nav>
            <div>

            </div>
        </div>
    )
}
