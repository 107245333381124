import React, {useState, useEffect} from 'react'
import Navbar from '../../components/layout/Navbar/Navbar'
import DarkmodeContext from '../../state/darkmodel-context';

export default function Layout({children}) {

    const isBrowser = () => typeof window !== "undefined"
    
    const [isDark, setIsDark] = useState(false);

    useEffect(() => {
      if(isBrowser()){
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
          setIsDark(true);
          document.body.classList.add('dark');
        }
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
          if (e.matches) {
            document.body.classList.add('dark');
          } else {
            document.body.classList.remove('dark');
          }
          setIsDark(e.matches)
        });
      }
    }, [])

    return (
        <DarkmodeContext.Provider value={{darkMode: isDark, setDarkMode: setIsDark}}>
            <Navbar/>
            {children}
        </DarkmodeContext.Provider>
    )
}
